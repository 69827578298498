import { Grid, Typography, Box, Tab, Skeleton, Button } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import TabPanelGeneral from "./components/TabPanelGeneral";
import TabPanelLocation from "./components/TabPanelLocation";
import TabPanelDetails from "./components/TabPanelDetails";
import DrawingsDialog from '../../../../components/dialogs/DrawingsDialog';
import CertificateDialog from '../../../../components/dialogs/CertificateDialog';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useSelector, useDispatch } from "react-redux";
import { selectUserInfo } from "../../../../../../../features/admin_panel/adminPanelSlice";
import { updateGeneralProjectInfo, publishProject } from "../../../../../../../features/projects/projectsSlice";
import ChoosePackage from "../../../../../components/ChoosePackageDialog/ChoosePackage";
import InfoDialog from "../../../../../components/InfoDialog/InfoDialog";
import React, { useState } from 'react';
import './styles/general_info.css'

export default function GeneralInfo({ project_data, purpose, delete_draw, delete_cert }) {
    const dispatch = useDispatch()
    const [draw_open, setDrawOpen] = useState(false)
    const [cert_open, setCertOpen] = useState(false)
    const [publish_open, setPublishOpen] = useState(false)
    const [info_dialog, setInfoDialog] = useState(false)

    const userInfo = useSelector(selectUserInfo)

    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const get_discount = () => {
        let price = project_data?.price
        let discount = project_data?.discount
        let discount_price = (price - (discount * price / 100)).toFixed(0)
        return `${discount_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    const success_publish = () => {
        dispatch(publishProject({projectId: project_data.id, data: {tariff: userInfo.tariff.id, published: true}})).then((res) =>
            enqueueSnackbar('Your ad has been successfully published!', { variant: 'success', style: { background: '#BD9A68' } })
        ).catch((err) => {
            enqueueSnackbar('Something went wrong!', { variant: 'error', style: { background: 'error' } })
        })
        setPublishOpen(false)
    }

    const success_info_dialog = () => {
        dispatch(updateGeneralProjectInfo({projectId: project_data.id, data: {status: 'on_review'}}))
        setInfoDialog(false)
    }

    return (
        <Grid container item xs={12} spacing={2} p={0}>
            <Grid item xs={12} >
                <Typography className="name_project_text">
                    {project_data?.name}
                </Typography>
                <Typography className="name_project_short_desc">
                    {project_data?.name
                        ? (project_data?.name)
                        : <Skeleton variant="rounded" height={20} sx={{ bgcolor: '#7e7e7e' }} />
                    }
                    {project_data?.name && project_data.location?.country && project_data.location?.region
                        ? ` from ${project_data.location?.country}, ${project_data.location?.region}`
                        : ''
                    }
                </Typography>
                <Typography className="discount_price">
                    {project_data?.discount
                        ?
                        <React.Fragment>
                            {project_data?.currency === 'USD' && <span>$</span>}
                            {project_data?.currency === 'EUR' && <EuroSharpIcon />}
                            <span>{`${project_data?.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                        </React.Fragment>
                        : ''
                    }

                </Typography>
                <Typography className="price_text">
                    {project_data?.price
                        ? <React.Fragment>
                            {project_data?.currency === 'USD' && <span>$</span>}
                            {project_data?.currency === 'EUR' && <EuroSharpIcon />}
                            {project_data?.discount ? get_discount() : `${project_data?.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            {project_data?.price && <span> (Excluding all tax or fees)</span>}
                        </React.Fragment>
                        : !project_data.details?.flat_for_land_basis && <Skeleton variant="rounded" height={20} width={'50%'} sx={{ bgcolor: '#7e7e7e', mt: '15px' }} />
                    }

                </Typography>
                <Typography className="dues_fee">{project_data?.price ? '(Dues or Additional VAT)' : ''}</Typography>

                {project_data.details?.flat_for_land_basis &&
                    (<Typography className="typography_flat_for_land" fontSize={'30px'}>(Flat for Land Basis)</Typography>)
                }
            </Grid>

            <Grid container xs={12} pl={2}>
                <Box width={'100%'}>
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                                <Tab label="General" value={1} />
                                <Tab label="Details" value={2} />
                                <Tab label="Location" value={3} />
                            </TabList>
                        </Box>
                        <TabPanel value={1} sx={{ padding: '5px 0 !important' }}> <TabPanelGeneral data={project_data} /> </TabPanel>
                        <TabPanel value={2} sx={{ padding: '5px 0 !important' }}> <TabPanelDetails data={project_data.details} /> </TabPanel>
                        <TabPanel value={3} sx={{ padding: '5px 0 !important' }}> <TabPanelLocation data={project_data.location} /> </TabPanel>
                    </TabContext>
                </Box>
            </Grid>

            <Grid item xs={12} mt={1} align='center'>
                <Button className="view_btn_overview" onClick={() => setCertOpen(true)}>View Certificates</Button>
                <Button className="view_btn_overview" onClick={() => setDrawOpen(true)}>View Drawings</Button>

                {purpose === 'view' && (userInfo.pk === project_data.owner || userInfo.is_superuser) &&
                    <React.Fragment>
                        {project_data.status === 'created' 
                            && 
                            <React.Fragment>
                                <Button className="view_btn_overview" onClick={() => setInfoDialog(true)}>Request for review</Button>
                                <Typography fontSize={11} color={'#BD9A68'}>Before making your ad published, go through the review stage.</Typography>
                            </React.Fragment>
                        }

                        {project_data.status === 'approved' && !project_data.published
                            && <Button className="view_btn_overview" onClick={() => setPublishOpen(true)}>Publish</Button>
                        }
                    </React.Fragment>
                }
            </Grid>

            <DrawingsDialog open={draw_open} setOpen={setDrawOpen} drawings={project_data.drawings} purpose={purpose} project={project_data} delete_draw={delete_draw} />
            <CertificateDialog open={cert_open} setOpen={setCertOpen} certificates={project_data.certificates} purpose={purpose} project={project_data} delete_cert={delete_cert} />
            <ChoosePackage isOpen={publish_open} setIsOpen={setPublishOpen} titleKey={'add_advert'} successFunc={success_publish}/>
            <InfoDialog isOpen={info_dialog} setIsOpen={setInfoDialog} successFunction={success_info_dialog} textType={'ad_on_review'}/>

            <SnackbarProvider autoHideDuration={2000} anchorOrigin={{ horizontal: 'right', vertical: 'top' }} />
        </Grid>
    )
}