import { Grid, Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

const TEXTS = {
    'delete_project': 'Are you sure you want to delete this project?',
    'delete_avatar': 'Are you sure you want to delete your avatar?',
    'delete_logo': 'Are you sure you want to delete the logo?',
    'approve': 'Are you sure to approve the project?',
    'reject': 'Are you sure to reject the project?',
    'ad_on_review': 'Are you sure you want to submit your ad for consideration?'
}

export default function InfoDialog({ isOpen, setIsOpen, successFunction, textType }) {
    const classes = useStyles()

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                className={classes.root}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText >
                        {TEXTS[textType]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} justifyContent={'space-evenly'}>
                        <Grid item>
                            <Button variant="outlined" onClick={successFunction}> Yes </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleClose}> Decline </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}