import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import makeApiRequest from '../../api/api'

const init_filters = {
    additional: {}
}

const initialState = {
    projects: [],
    status: 'idle',
    unconfirmed_projects: [],
    un_status: 'idle',
    specification: {},
    selected_tariff_id: null,
    selected_project_id: null,
    filters: init_filters,
    next_page: 2,
    page_count: 0
}


const update_state_from_payload = (state, prId, action) => {
    for (let field in action.payload) {
        state.projects[prId][field] = action.payload[field]
    }
}

// Fetch actions
export const fetchProjects =
    createAsyncThunk('projects/fetchProjects', async ({ filters, page_size, search }) => {
        let page = page_size ? page_size : 1
        let response

        if (filters) {
            const { additional, ...rest } = filters;
            response = await makeApiRequest(`/api/projects/`, 'GET', {}, false, { params: { ...rest, ...additional, 'page': page, search: search } })
        }
        else {
            response = await makeApiRequest(`/api/projects/`, 'GET', {}, false, { params: { 'page': page, search: search } })
        }

        return response.data
    }
    )

export const fetchUnconfirmedProjects =
    createAsyncThunk('projects/fetchUnconfirmedProjects', async () => {
        const response = await makeApiRequest('/api/unconfirmed_projects/')
        return response.data
    }
    )

export const fetchSingleProject =
    createAsyncThunk('project/fetchSingleProject', async (project_id) => {
        const response = await makeApiRequest(`/api/projects/${project_id}/`)
        return response.data
    }
    )

// Update actions
export const updateGeneralProjectInfo =
    createAsyncThunk('projects/updateGeneralProjectInfo', async ({ projectId, data }) => {
        const response = await makeApiRequest(`/api/projects/${projectId}/`, 'PATCH', data)
        return response.data
    }
    )

export const updateDetailsProjectInfo =
    createAsyncThunk('project/updateDetailsProjectInfo', async ({ type_of_estate, projectId, detailsId, data }) => {
        const response = await makeApiRequest(`api/${type_of_estate}/${detailsId}/`, 'PATCH', data)
        return {data: response.data, projectId: projectId}
    })

export const updateMediaPosition =
    createAsyncThunk('projects/updateMediaPosition', async ({ position, media_id, project_id }) => {
        await makeApiRequest(`/api/project_media/${media_id}/`, 'PATCH', { 'position': position })
        return { 'position': position, 'media_id': media_id, 'project_id': project_id }
    }
    )

// Delete actions
export const deleteProject =
    createAsyncThunk('project/deleteProject', async (project_id) => {
        const response = await makeApiRequest(`/api/projects/${project_id}/`, 'DELETE')
        return response.data
    }
    )

export const deleteMedia =
    createAsyncThunk('projects/deleteMedia', async ({ media_id, project_id }) => {
        await makeApiRequest(`/api/project_media/${media_id}/`, 'DELETE')
        return { 'project': project_id, 'pk': media_id }
    }
    )

export const deleteAllMedia =
    createAsyncThunk('project/deleteAllMedia', async ({ project_id }) => {
        await makeApiRequest('/api/project_media/destroy_by_project/', 'DELETE', {}, false, {
            params: {
                'project_id': project_id
            }
        })
        return { 'project': project_id }
    }
    )

export const deleteDrawing =
    createAsyncThunk('projects/deleteDrawing', async ({ draw_id, project_id }) => {
        await makeApiRequest(`/api/project_drawings/${draw_id}/`, 'DELETE')
        return { 'project': project_id, 'pk': draw_id }
    }
    )

export const deleteCertificate =
    createAsyncThunk('projects/deleteCertificate', async ({ cert_id, project_id }) => {
        await makeApiRequest(`/api/project_certificates/${cert_id}/`, 'DELETE')
        return { 'project': project_id, 'pk': cert_id }
    }
    )

// Add-al actions
export const publishProject =
    createAsyncThunk('project/publishProject', async ({projectId, data}) => {
        const response = await makeApiRequest(`/api/projects/${projectId}/make_publish/`, 'PATCH', data)
        return response.data
    }
    )

export const approveRejectProject =
    createAsyncThunk('project/confirmProject', async ({ projectId, data }) => {
        const response = await makeApiRequest(`/api/unconfirmed_projects/${projectId}/`, 'PATCH', data)
        return response.data
    }
    )

export const applyProjectFilter =
    createAsyncThunk('project/applyProjectFilter', async () => {

    })

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjectId(state, action) {
            state.selected_project_id = action.payload.project_id
        },
        updateLocationProjectInfo(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            state.projects[pr_id].location = action.payload['data']
        },
        addProjectMedia(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let media_length = state.projects[pr_id].media.length
            Object.keys(action.payload['data']).forEach((k) => {
                state.projects[pr_id].media[Number(media_length) + Number(k)] = action.payload['data'][k]
            })
        },
        addProjectDrawings(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let drawings_length = state.projects[pr_id].drawings.length
            Object.keys(action.payload['data']).forEach((k) => {
                state.projects[pr_id].drawings[Number(drawings_length) + Number(k)] = action.payload['data'][k]
            })
        },
        addProjectCertificates(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let certificates_length = state.projects[pr_id].certificates.length
            Object.keys(action.payload['data']).forEach((k) => {
                state.projects[pr_id].certificates[Number(certificates_length) + Number(k)] = action.payload['data'][k]
            })
        },

        // Filters
        putFilterData(state, action) {
            if (action.payload.parent_node) {
                state.filters[action.payload.parent_node][action.payload.field] = action.payload.data
            }
            else {
                state.filters[action.payload.field] = action.payload.data
            }
        },

        cleanFilterData(state, action) {
            if (action.payload?.node === 'additional') {
                delete state.filters['additional'][action.payload.field]
            }
            else {
                delete state.filters[action.payload.field]
            }
        },

        cleanFilterAllData(state, action) {
            state.filters = init_filters
        },

        setTariffId(state, action) {
            state.selected_tariff_id = action.payload.tariffId
        }

    },
    extraReducers(builder) {
        builder
            // Fetch actions
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.projects = action.payload.results
                state.page_count = action.payload.total_pages

                const next_ind = action.payload.next ? action.payload.next?.indexOf('page=')+5 : false
                state.next_page = next_ind ? Number(action.payload.next[next_ind]) : action.payload.total_pages + 1
                // state.filters = init_filters
            })

            .addCase(fetchUnconfirmedProjects.fulfilled, (state, action) => {
                state.un_status = 'succeeded'
                state.unconfirmed_projects = action.payload
            })

            .addCase(fetchSingleProject.fulfilled, (state, action) => {
                state.projects = state.projects.concat(action.payload)
                state.selected_project_id = action.payload.id
            })

            // Update actions
            .addCase(updateGeneralProjectInfo.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
                update_state_from_payload(state, pr_id, action)
                for (let field in action.payload) {
                    state.projects[pr_id][field] = action.payload[field]
                }
            })

            .addCase(updateDetailsProjectInfo.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['projectId'])
                for (let field in action.payload['data']) {
                    state.projects[pr_id].details[field] = action.payload['data'][field]
                }
            })

            .addCase(updateMediaPosition.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project_id'])
                let media_id = state.projects[pr_id].media.findIndex((item) => item.id === action.payload['media_id'])
                state.projects[pr_id].media[media_id].position = action.payload['position']
                state.projects[pr_id].media = state.projects[pr_id].media.sort((a, b) => a.position > b.position ? 1 : -1)
            })

            // Delete actions
            .addCase(deleteMedia.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let im_id = state.projects[pr_id].media.findIndex((image) => image.id === action.payload['pk'])
                state.projects[pr_id].media.splice(im_id, 1)
            })

            .addCase(deleteAllMedia.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                state.projects[pr_id].media = []
            })

            .addCase(deleteDrawing.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let draw_id = state.projects[pr_id].drawings.findIndex((draw) => draw.id === action.payload['pk'])
                state.projects[pr_id].drawings.splice(draw_id, 1)
            })

            .addCase(deleteCertificate.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let cert_id = state.projects[pr_id].certificates.findIndex((cert) => cert.id === action.payload['pk'])
                state.projects[pr_id].certificates.splice(cert_id, 1)
            })

            .addCase(publishProject.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
                for (let field in action.payload) {
                    state.projects[pr_id][field] = action.payload[field]
                }
            })

            .addCase(approveRejectProject.fulfilled, (state, action) => {
                let un_pr_id = state.unconfirmed_projects.findIndex((project) => project.id === action.payload['id'])
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
                if (pr_id !== -1) {
                    state.projects[pr_id].status = action.payload['status']
                }
                state.unconfirmed_projects.splice(un_pr_id, 1)
            })
    }
})

export default projectsSlice.reducer

export const selectAllProjects = (state) => state.projects.projects
export const selectAllUnconfirmedProjects = (state) => state.projects.unconfirmed_projects

export const selectProjectId = (state) => state.projects.selected_project_id

export const selectProject = (state) => {
    if (!state.projects.selected_project_id) {
        return null
    }
    return state.projects.projects.find((project) => project.id === state.projects.selected_project_id)
}

export const countUnconfirmedProjects = (state) =>
    state.projects.projects.filter((project) => project.status === 'created').length

export const selectStatusProjects = (state) => state.projects.status
export const selectUnStatusProjects = (state) => state.projects.un_status

export const selectProjectFilters = (state) => state.projects.filters
export const selectFilterEstateCategory = (state) => state.projects?.filters?.estate_category

export const selectSelectedTariffId = (state) => state.projects.selected_tariff_id

export const selectPageCount = (state) => state.projects.page_count
export const selectCurrentPage = (state) => state.projects.next_page - 1


export const { setProjectId, updateLocationProjectInfo,
    addProjectMedia, addProjectDrawings, addProjectCertificates, putFilterData, cleanFilterAllData,
    cleanFilterData, setTariffId } = projectsSlice.actions
