import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import makeApiRequest from "../../api/api";

const initialState = {
    user: {},
    navigate: {
        collapseItems: {projects: false, profile: false },
        chosenItem: 'projects',
        chosenCollapseItem: 'my_project'
    }
}

// FETCH
export const fetchUserInfo = 
    createAsyncThunk('adminPanel/fetchUserInfo', async() => {
       const response = await makeApiRequest('/auth/user/user_info/')
       return response.data
    }
)

// DELETE
export const removeCompanyLogo = 
    createAsyncThunk('adminPanel/removeCompanyLogo', async() => {
        await makeApiRequest('/auth/user_company/remove_logo/', 'DELETE')
    }
)

export const removeUserAvatar = 
    createAsyncThunk('adminPanel/removeUserAvatar', async() => {
        await makeApiRequest('/auth/user/remove_avatar/', 'DELETE')
    }
)

// Update
export const updateUserInfo = 
    createAsyncThunk('adminPanel/updateUserInfo', async({data, userId}) => {
        const response = await makeApiRequest(`/auth/user/${userId}/`, 'PATCH', data, false, { headers: {'Content-Type': 'multipart/form-data'}})
        return response.data
    }
)

export const updateCompanyInfo = 
    createAsyncThunk('adminPanel/updateCompanyInfo', async({data, companyId}) => {
        const response = await makeApiRequest(`/auth/user_company/${companyId}/`, 'PATCH', data, false, {headers: {'Content-Type': 'multipart/form-data'}})
        return response.data
    }
)

export const updateTariffInfo =
    createAsyncThunk('adminPanel/updateTariffInfo', async({data, tariffId}) => {
        const response = await makeApiRequest(`/auth/tariffs/${tariffId}/`, 'PATCH', data)
        return response.data
})

export const changePassword =
    createAsyncThunk('adminPanel/changePassword', async({data}) => {
        await makeApiRequest('/auth/change_password/', 'PUT', data)
    })

const adminPanelSlice = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {
        setChosenItem(state, action){
            state.navigate.chosenItem = action.payload.chosenItem
        },

        setCollapseItems(state, action){
            let key = action.payload.collapseItemKey
            let value = action.payload.collapseItemValue
            if (key in state.navigate.collapseItems) {
                state.navigate.collapseItems[key] = value
            }
        },
        setChosenCollapseItem(state, action){
            state.navigate.chosenCollapseItem = action.payload.chosenCollapseItem
        },
    },
    extraReducers(builder) {
        builder
        .addCase(removeUserAvatar.fulfilled, (state, action) => {
            state.user.avatar = null
        })

        .addCase(removeCompanyLogo.fulfilled, (state, action) => {
            state.user.company.logo = null
        })
        
        .addCase(fetchUserInfo.fulfilled, (state, action) => {
            state.user = action.payload
        })

        .addCase(updateUserInfo.fulfilled, (state, action) => {
            state.user = action.payload
        })

        .addCase(updateCompanyInfo.fulfilled, (state, action) => {
            state.user.company = action.payload
        })

        .addCase(updateTariffInfo.fulfilled, (state, action) => {
            state.user.tariff = action.payload
        })
    }
})

export default adminPanelSlice.reducer

export const selectUserInfo = (state) => state.adminPanel.user
export const selectUserLogo = (state) => state.adminPanel.user.company.logo

export const selectFilterInfo = (state) => state.adminPanel.filters

export const selectChosenItem = (state) => state.adminPanel.navigate.chosenItem
export const selectCollapseItems = (state) => state.adminPanel.navigate.collapseItems
export const selectChosenCollapseItem = (state) => state.adminPanel.navigate.chosenCollapseItem


export const {setChosenItem, setCollapseItems, setChosenCollapseItem} = adminPanelSlice.actions

