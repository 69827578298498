import { Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from 'react';
import { selectProject, fetchSingleProject } from '../../../../features/projects/projectsSlice';
import { useDispatch, useSelector } from "react-redux";
import GeneralInfo from '../AddProject/tabs/Overview/ui/GeneralInfo';
import MediaSlider from '../../components/Slider/Slider';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { FeaturesInfo } from '../AddProject/tabs/Overview/ui/FeaturesInfo';
import MenuProjectOptions from './components/MenuProjectOptions';
import InfoDialog from '../../components/InfoDialog/InfoDialog';
import { useParams } from 'react-router-dom';
import makeApiRequest from '../../../../api/api';
import { useStyles } from './styles';
import Loader from '../../../components/Loader/Loader'

const API_KEY = process.env.REACT_APP_GOOGLE_KEY

export default function ViewProject({ purpose }) {
    const classes = useStyles()
    const project = useSelector(selectProject)
    const position = { lat: Number(project?.location?.lat), lng: Number(project?.location?.lng) }
    const [accordion, setAccordion] = useState({ 1: true, 2: false })
    const [progress_status, set_progress_status] = useState(false)
    const [delete_dialog, set_delete_dialog] = useState(false)

    const dispatch = useDispatch()
    const { id } = useParams()

    function setValue() {
        setAccordion({ 1: !accordion[1], 2: !accordion[2] })
    }

    const handleDelete = () => {
        makeApiRequest(`/api/projects/${project.id}/`, 'DELETE').then(res => {
            window.location.href = '/admin_panel/projects/my_projects'
        })
    }

    useEffect(() => {
        if (!project) {
            dispatch(fetchSingleProject(id)).then((res) => {
            })
        }
    }, [])

    return (
        <Grid container className={classes.root} alignContent={'baseline'} justifyContent={'center'} p={2}>
            {project &&
                <Grid container alignItems={'flex-start'} p={1}>
                    <Grid container item p={0} xs={1} sm={.5}>
                        <MenuProjectOptions project_id={project?.id} project_name={project?.name} setProgress={set_progress_status} set_delete_dialog={set_delete_dialog} />
                    </Grid>
                    <Grid item xs={11} sm={11.5} lg={4.5} >
                        <Grid item pl={2} xs={12}>
                            <Typography sx={{textTransform: 'capitalize'}}
                                color={
                                project.status === 'on_review' 
                                    ? '#ffc107'
                                    : project.status === 'approved'
                                        ? '#8bc34a'
                                        : project.status === 'rejected' 
                                            ? '#f44336'
                                            : project.status === 'created'
                                                ? '#fff'
                                                : 'grey'
                            }>
                                {project.status.replaceAll('_', ' ')}
                            </Typography>
                        </Grid>
                        <Grid item pl={2} xs={12}>
                            <Typography color='gray'>
                                {project.published ? `published/${project?.publication_date}` : 'not published'}
                            </Typography>
                            <Typography color='gray'>
                                create date: {project.create_date}
                            </Typography>
                        </Grid>
                        <GeneralInfo
                            project_data = {
                                {
                                    ...project, 
                                    drawings: Object.values(project?.drawings).map((item, index) => [item.drawing, index]),
                                    certificates: Object.values(project?.certificates).map((item, index) => [item.certificate, index])
                                }
                            } 
                            purpose={'view'}
                        />
                    </Grid>

                    <Grid container item p={0} alignSelf={'stretch'} xs={12} lg={7} alignItems={'center'} justifyContent={'flex-end'} alignContent={'flex-start'} className={classes.elBlock}>
                        <Grid container p={1} pt={{ md: 2, lg: 0 }} flexWrap={'nowrap'}>

                            <Grid item className={[classes.elAccordion, accordion[1] && classes.elAccordionShow].join(' ')} >

                                <MediaSlider mediaFiles={Object.values(project?.media).map((item) => {
                                    return {
                                        'file': item.file,
                                        'type': item.type.slice(0, 5),
                                        'position': item.position
                                    }
                                })} purpose={purpose} />
                            </Grid>

                            <Grid item className={[classes.elAccordion, accordion[2] && classes.elAccordionShow].join(' ')} >
                                <APIProvider apiKey={API_KEY} >
                                    <div style={{ height: '50vh', width: '90%' }}>
                                        <Map center={position} defaultZoom={12} >
                                            <Marker position={position} />
                                        </Map>
                                    </div>
                                </APIProvider>

                            </Grid>

                            <Grid item xs={.5} m={1} alignSelf={'stretch'} alignContent={'center'} className={classes.navigateBlock} >
                                <IconButton edge={false} size='small' onClick={() => { setValue() }}>
                                    {!accordion[1] && <KeyboardArrowRightIcon fontSize='small' color='primary' />}
                                    {accordion[1] && <KeyboardArrowLeftIcon fontSize='small' color='primary' />}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} >
                        <FeaturesInfo features={project.details} />
                    </Grid>
                </Grid>
            }

            {
                progress_status && <Loader />
            }

            <InfoDialog isOpen={delete_dialog} setIsOpen={set_delete_dialog} successFunction={handleDelete} textType={'delete_project'} />

        </Grid>
    )
}