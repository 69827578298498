import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row({ row }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.first_name}</TableCell>
                <TableCell align="right">{row.last_name}</TableCell>
                <TableCell align="right">{row.date_joined}</TableCell>

                <TableCell align="right">
                    {row.have_access_like_investor && 'investor'}
                    {row.have_access_like_realtor && 'realtor'}
                    {!row.have_access_like_investor && !row.have_access_like_realtor && 'root'}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
            </TableRow>


            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell align="right">Email</TableCell>
                                        <TableCell align="right">Role</TableCell>
                                        <TableCell align="right">Tariff</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Benefit</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.data.map((historyRow, ind) => (
                                        <TableRow key={ind}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.first_name}
                                            </TableCell>
                                            <TableCell>{historyRow.last_name}</TableCell>
                                            <TableCell align="right">{historyRow.email}</TableCell>
                                            <TableCell align="right">{historyRow.tariff?.role}</TableCell>
                                            <TableCell align="right">{historyRow.tariff?.title}</TableCell>
                                            <TableCell align="right">{historyRow.tariff?.price}</TableCell>
                                            <TableCell align="right">{historyRow.benefit}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}


export default function RootTable({ data }) {
    const [rows, setRows] = React.useState([])

    React.useEffect(() => {
        setRows(data.results)
    }, [])
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">Registration Date</TableCell>
                        <TableCell align="right">Role</TableCell>
                        <TableCell align="right">Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}