import { Grid, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { selectUserInfo } from "../../../../../features/admin_panel/adminPanelSlice"
import ProfilePackageCard from "./components/ProfilePackageCard/ProfilePackageCard"
import CancelPackages from "./components/CancelPackage/CancelPackage"
import PackageCard from "../../../Pricing/components/PackageCard"
import ExtraAdditions from "./components/ExtraAdditions/ExtraAdditions"
import { RootTariff } from "../../../Pricing/components/constants"
import { useStyles } from "./styles"
import { useState } from "react"

export default function Packages() {
    const classes = useStyles()
    const userInfo = useSelector(selectUserInfo)
    const [action, setAction] = useState('')
    const [currentTariff, setCurrentTariff] = useState()

    const get_properties = () => {
        if (currentTariff.title === 'unlimited') {
            return RootTariff.properties
        }
        return [
            { label: `Can Add ${currentTariff.ads_amount} Ads (for All Estate Types)`, active: true, name: 'ads_amount' },
            { label: `Highlight - Promotion (${currentTariff.highlights}) Ad`, active: currentTariff.highlights > 0 ? true : false, name: 'highlights' },
            { label: 'Customer and Target Market Analysis ', active: currentTariff.support, name: 'support' },
        ]
    }

    return (
        <Grid container alignItems="flex-start" justifyContent='space-evenly' className={classes.root} p={1} >
            <Grid container item p={0} pt={2} pb={2} xs={12} sx={{ mb: { xs: 2, lg: 0 } }}
                component={Paper} className={classes.cardBlock} justifyContent='space-evenly' alignItems={'start'}>

                <Grid container item xs={12} lg={6} p={0} alignSelf={'stretch'} alignItems={'baseline'}>
                    <Grid container justifyContent="center">
                        <Typography variant="h4">Your Plan \ Packages Informations</Typography>
                    </Grid>
                    <Grid container mt={4} justifyContent={'center'} mb={2}>
                        <Grid container item p={0} xs={12} xl={6} justifyContent={'center'}>
                            <ProfilePackageCard packageInfo={userInfo.tariff} userInfo={userInfo} setAction={setAction} setCurrentTariff={setCurrentTariff} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} lg={6} height={action === 'details' ? 'auto' : '50vh'}>
                    <Grid item xs={12} component={Paper} className={classes.contentBlock} height={'100%'} alignContent={'center'} pt={2} pb={2}>
                        {action === 'cancel_package' && <CancelPackages setAction={setAction} />}
                        {action === 'details' &&
                            <PackageCard price={currentTariff.price} properties={get_properties()} label={`${currentTariff.title} Package`} description={' '} duration={currentTariff.month_duration}
                            />
                        }
                        {action === 'buy_addition' && <ExtraAdditions />}
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}